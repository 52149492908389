import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import { validEmail } from '../../Components/Elements/Regex';
import LoginModal from "../Modals/login_modal";
import Alert from 'react-bootstrap/Alert';
import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function Footer() {
    const location = useLocation();
    const didMountRef = useRef(true);
    const [show, setShow] = useState(false);
    const [settingData, setSettingData] = useState([]);
    const [settingImagePath, setSettingImagePath] = useState("");
    const [footer1, setFooter1] = useState("");
    const [footer2, setFooter2] = useState("");
    const [footer3, setFooter3] = useState("");
    const [footer4, setFooter4] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [setSession, SetSession] = useState('')
    const [whatsappUrl, setWhatsappUrl] = useState('')
    const [accountStatus, SetAccountStatus] = useState(false)
    const handleShow = () => setShow(true);
    const handleChildData = (status) => {
        setShow(status)
    };
    const [newsletterDetails, setNewsletterDetails] = useState({
        newsletter_email: "",
    });
    useEffect(() => {
        if (didMountRef.current) {
            getSettingsData();
            getFooterData();
            SetSession(localStorage.getItem('USER_TOKEN'))
            const currentPath = location.pathname;
            const isAccountOverview = currentPath.includes("/account/account-overview");
            const isAddressPage = currentPath.includes("/account/address");
            const isWishlistPage = currentPath.includes("/account/wishlist");
            const ishelpandsupportPage = currentPath.includes("/account/help-and-support");
            const isaboutusPage = currentPath.includes("/account/about-us");

            if (isAccountOverview || isWishlistPage || isAddressPage || ishelpandsupportPage || isaboutusPage) {
                SetAccountStatus(true)
            } else {
                SetAccountStatus(false)
            }
        }
        didMountRef.current = false;
    }, []);
    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status == "success") {
                setSettingData(res.sitesettings);
                setSettingImagePath(res.setting_image_path)
                setWhatsappUrl(res.sitesettings.admin_whatsapp_no ? "https://wa.me/" + res.sitesettings.admin_whatsapp_no : "")

            }
        });
    };
    const getFooterData = () => {
        ApiService.fetchData("footer").then((res) => {
            if (res.status === "success") {
                if (res.footerData?.footer_desc1) {
                    setFooter1(res.footerData.footer_desc1);
                }
                if (res.footerData?.footer_desc2) {
                    setFooter2(res.footerData.footer_desc2);
                }
                if (res.footerData?.footer_desc3) {
                    setFooter3(res.footerData.footer_desc3);
                }
                if (res.footerData?.footer_desc4) {
                    setFooter4(res.footerData.footer_desc4);
                }
            }
        });
    };

    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setNewsletterDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const resetNewsletterForm = () => {
        setNewsletterDetails({ newsletter_email: "" });
    };
    const newsletterProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("newsletterRequired");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (!validEmail.test(newsletterDetails.newsletter_email)) {
                setErrorMessage("Please enter valid Email Id");
                return false;
            }
            ApiService.postData('newsletter-process', newsletterDetails).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message)
                    resetNewsletterForm();
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, 2000);
                } else {
                    setErrorMessage(res.message)
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 2000);
                }
            });
        }
    };

    return (
        <>
            <BrowserView>
                <div className="footerIocn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/img/f05.png" alt="Shipping Icon"/></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Free Shipping</h6>
                                        <p className="tx-12 mb-0">Order above AED 500</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/img/f04.png" alt="Save Icon"/></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Save Money</h6>
                                        <p className="tx-12 mb-0">All products at lowest price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/img/f01.png" alt="Deal Icon"/></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Best Deal Offer</h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="footerIocnBox">
                                    <div className="ficon"><img src="/img/f02.png" alt="Quality Icon"/></div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Quality assurance </h6>
                                        <p className="tx-12 mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <div className="footerTop">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <h5>Join our newsletter</h5>
                                    <p>Register now to get latest updates on promotions & coupons.Don’t worry, we not spam!</p>
                                </div>
                                <div className="col-lg-5">
                                    {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                                    {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                                    <div className="subscribe-form">
                                        <input
                                            className="email newsletterRequired"
                                            type="email"
                                            placeholder="Enter your email here.."
                                            name="newsletter_email"
                                            value={newsletterDetails.newsletter_email}
                                            onChange={(e) => onTodoChange(e)}
                                        />
                                        <div className="subscribe-button">
                                            <input
                                                id="mc-embedded-subscribe"
                                                className="button"
                                                type="button"
                                                name="subscribe"
                                                value="Subscribe"
                                                onClick={newsletterProcess}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerMiddle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <a href="/" className="footer-logo mb-3">
                                        <img
                                            src={settingData.footer_logo != null ? settingImagePath + settingData.footer_logo : "/img/logodefault.png"} alt={settingData.footer_logo}
                                            width={125}
                                            height={80}
                                        />
                                    </a>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. </p>
                                    <div className="footer-contact">
                                        <ul>
                                            <li>
                                                <a href="https://maps.app.goo.gl/sq5A4pKKS3bpNgBS8" target="new">
                                                    <i className="d-icon-map mr-5"></i>
                                                    <span dangerouslySetInnerHTML={{ __html: settingData.address }}></span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"tel:" + settingData.admin_mobile}>
                                                    <i className="d-icon-phone mr-5"></i>
                                                    <span>{settingData.admin_mobile}</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"mailto:" + settingData.admin_email}>
                                                    <i className="fa fa-envelope  mr-5"></i>
                                                    <span>{settingData.admin_email}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h6 className="footer-title mb-15">Popular Categories</h6>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="footer-list">
                                                <ul>
                                                    <li><a href="/collection/category/sugar-baking">Sugar & Baking</a></li>
                                                    <li><a href="/collection/category/breakfast-bars">Breakfast</a></li>
                                                    <li><a href="/collection/category/condiments-marinades">Condiments & Marinades</a></li>
                                                    <li><a href="/collection/category/dry-fruits">Dry Fruits</a></li>
                                                    <li><a href="/collection/category/tinsjars-packets">Tin & Jars</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="footer-list">
                                                <ul>
                                                    <li><a href="/collection/category/snacks">Chips & Snacks</a></li>
                                                    <li><a href="/collection/category/beverages">Beverages</a></li>
                                                    <li><a href="/collection/category/bio-organic-foods">Organic Foods</a></li>
                                                    <li><a href="/collection/category/jam-honey-spreads">Honey & Spreads</a></li>
                                                    <li><a href="/collection/category/tea-coffee">Tea & Coffee</a></li>
                                                   

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <h6 className="footer-title mb-15">Useful Links</h6>
                                    <div className="footer-list">
                                        <ul>
                                            <li><a href="/about-us">About Us</a></li>
                                            <li><a href="/support">Customer Support</a></li>
                                            <li><a href="/contact-us">Contact Us</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <h6 className="footer-title mb-15">Quick Links</h6>
                                    <div className="footer-list">
                                        <ul>
                                            <li><a href="/returns-exchange">Returns & Exchanges</a></li>
                                            <li><a href="/terms-of-use">Terms Of Use</a></li>
                                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="footerBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="tx-gray mb-0 tx-13">Copyright @ 2024 | All Right Reserved Chokhi Dhani Foods UAE</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="d-flex justify-content-end">
                                        <img src="/img/payments.png"></img>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </footer>


                {whatsappUrl ? (
                    <div className="whatsapp"><a href={whatsappUrl} target="_blank"><img src="/img/whatsapp1.png" alt="WhatsApp" /></a></div>) : null}

            </BrowserView>

            <MobileView>
          
                <div className="appBottomMenu">
                    <a href="/" className={location.pathname === "/" ? "item active" : "item"}>
                        <div className="col">
                            <i className="ri-home-smile-line"></i>
                            <span>Home</span>
                        </div>
                    </a>
                    <a href="/category" className={location.pathname.includes("/category") ? "item active" : "item"}>
                        <div className="col">
                            <i className="ri-artboard-2-line"></i>
                            <span>Categories</span>
                        </div>
                    </a>
                    {setSession ?
                        <a href="/account/orders" className={location.pathname.includes("/account/orders") ? "item active" : "item"}>
                            <div className="col">
                                <i className="ri-truck-line"></i>
                                <span>Orders</span>
                            </div>
                        </a> :
                        <a href="#" className="item" onClick={handleShow}>
                            <div className="col">
                                <i className="ri-truck-line"></i>
                                <span>Orders</span>
                            </div>
                        </a>}
                    <a href="/cart" className={location.pathname.includes("/cart") ? "item active" : "item"}>
                        <div className="col">
                            <i className="ri-shopping-bag-3-line"></i>
                            <span>Cart</span>
                        </div>
                    </a>
                    {setSession ?
                        <a href="/account/account-overview" className={accountStatus ? "item active" : "item"}>
                            <div className="col">
                                <i className="ri-user-line"></i>
                                <span>Account</span>
                            </div>
                        </a> : <a href="#" className="item" onClick={handleShow}>
                            <div className="col">
                                <i className="ri-user-line"></i>
                                <span>Account</span>
                            </div>
                        </a>
                    }
                </div>
                {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
            </MobileView>
            {/* <div className="whatsapp-mobile"><a href={whatsappUrl} target="new"><img src="/img/whatsapp1.png" ></img></a></div> */}
            <ScrollToTop smooth style={{ bottom: "124px", right: "23px" }} > <i className="fas fa-chevron-up"></i> </ScrollToTop>

        </>
    );
}
export default Footer;
