import React, { useEffect, useState, useRef } from "react";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
} from "react-device-detect";
import { ColorRing } from 'react-loader-spinner'
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import SpinnerLoader from "../Elements/spinner_loader";
import multiCurrency from "../../Components/Elements/multi_currrency";
import constant from "../../Components/Services/constant";
import Button from "react-bootstrap/Button";

import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { useNavigate } from "react-router-dom";

function Header({ cartCount = 0, state = "" }) {
    const didMountRef = useRef(true);
    const multiCurrencyData = multiCurrency();
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleChildData = (status) => {
        setShow(status);
    };
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [spinnerLoadingDetect, setSpinnerLoadingDetect] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const handleShowCart = () => setShowCart(true);
    const handleChildCartData = (status) => {
        setShowCart(status);
    };
    const [cartcounttest, setcartcounttest] = useState(cartCount);
    const handleClose = () => {
        setShowCart(false);
    };

    const [showMenuCart, setShowMenuCart] = useState(false);
    const handleShowMenuCart = () => setShowMenuCart(true);
    const handleChildMenuCartData = (status) => {
        setShowMenuCart(status);
    };
    const handleMenuClose = () => {
        setShowMenuCart(false);
    };
    const [settingData, setSettingData] = useState([]);
    const [settingImagePath, setSettingImagePath] = useState("");
    const [setSession, SetSession] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [headerdata, setHeaderData] = useState({});
    const [deliveryLocationData, setDeliveryLocationData] = useState({});
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    useEffect(() => {
        if (didMountRef.current) {
            handleChangeCurrency();
            getheaderdata();
            getSettingsData();

            SetSession(localStorage.getItem("USER_TOKEN"));
        }
        didMountRef.current = false;
        if (isBrowser) {
            const handleScroll = () => {
                if (window.scrollY > 200) {
                    document.getElementById("header_top").classList.add("fixed-head");
                    const navbarHeight =
                        document.querySelector(".fixed-header").offsetHeight;
                    document.body.style.paddingTop = navbarHeight + "px";
                } else {
                    document.getElementById("header_top").classList.remove("fixed-head");
                    document.body.style.paddingTop = "0";
                }
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
        if (isMobile) {
            const handleScroll = () => {
                if (window.scrollY > 200) {
                   document.getElementById("mobile_top").classList.add("sticky");
                    const navbarHeight =
                        document.querySelector(".ed-header").offsetHeight;
                    document.body.style.paddingTop = navbarHeight + "px";
                }else {
                    document.getElementById("mobile_top").classList.remove("sticky");
                    document.body.style.paddingTop = "0";
                }
            };
            
            if (isMobile) {
                window.addEventListener("scroll", handleScroll);
            
                return () => {
                    window.removeEventListener("scroll", handleScroll);
                };
            }
        }
        
        
    }, []);

    const getSettingsData = () => {
        ApiService.fetchData("settings").then((res) => {
            if (res.status == "success") {
                setSettingData(res.sitesettings);
                setSettingImagePath(res.setting_image_path);
            }
        });
    };
    const handleBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setSearchTerm("");
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 2) {
                fetchSearchResults();
            }
        }, 300); // Debounce time (adjust as needed)

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const getheaderdata = () => {
        ApiService.fetchData("header-data").then((res) => {
            if (res.status === "success") {
                setHeaderData(res.headerdata);
            }
        });
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchSearchResults = () => { 

            const dataString = {
                query: searchTerm,

            };
            ApiService.postData("getsearchdata", dataString).then((res) => {
                if (res.status === "success") {
                    setSearchResults(res.data);
                } else {
                }
            });
        // }
    };
    const wrapperRef = useRef(null);

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const [selectedCurrency, setSelectedCurrency] = useState("INR");

    const handleChangeCurrency = () => {
        const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
        const parsedMultiCurrencySession = MultiCurrencySession
            ? JSON.parse(MultiCurrencySession)
            : {};
        let selectedValue = "";

        if (parsedMultiCurrencySession) {
            selectedValue = parsedMultiCurrencySession.cr_currency_select;
        } else {
            selectedValue = selectedCurrency;
        }

        const dataString = {
            selectedValue: selectedValue,
        };
        //setSpinnerLoading(true);
        ApiService.postData("currency-rates", dataString).then((res) => {
            if (res.status === "success") {
                setSelectedCurrency(selectedValue);
                localStorage.setItem(
                    "MULTI_CURRENCY",
                    JSON.stringify(res.rowCurrencyRates)
                );
                //setSpinnerLoading(false);
            } else {
                //setSpinnerLoading(false);
            }
        });
    };

    const [showLocation, setShowLocation] = useState(false);
    const [backdrop, setBackdrop] = useState("static");
    const [locationError, setLocationError] = useState("");
    const [address, setAddress] = useState("");
    const handleCloseLocation = () => setShowLocation(false);
    const handleShowLocation = () => {
        setBackdrop("true");
        setShowLocation(true);
        setLocationError("");
        
    };

    useEffect(() => {
        const storedLocation = JSON.parse(
            localStorage.getItem("DELIVERY_LOCATION")
        );
        if (storedLocation && storedLocation.user_lng) {
            setDeliveryLocationData(storedLocation);
        } else {
            // setShowLocation(true);
        }
    }, []);
    const { suggestions: { status, data }, setValue, } = usePlacesAutocomplete({ requestOptions: { componentRestrictions: { country: 'AE' } }, });
    const fetchData = async () => {
        try {
            setSpinnerLoadingDetect(true);
            setValue([])
            setAddress("");
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        ApiService.fetchData("getsellerlocationapi/" + position.coords.latitude + '/' + position.coords.longitude).then((res) => {
                            if (res.seller_status == 1) {

                                geocodelocation(position.coords.latitude, position.coords.longitude)
                            } else {
                                setLocationError("Chokhi Dhani Foods UAE is currently unavailable at this location. Please choose an alternative location.");
                            }
                            setSpinnerLoadingDetect(false);
                        });
                    },
                    (error) => {
                        setLocationError("We do not have permission to determine your location. Please enter manually.");
                        setSpinnerLoadingDetect(false);
                    }
                );
            } else {
                setLocationError("We do not have permission to determine your location. Please enter manually.");
                setSpinnerLoadingDetect(false);
            }
        } catch (error) {
            setLocationError(error);
            setSpinnerLoadingDetect(false);
        }
    };

    const handleSelect = async (value) => {
        try {
            setValue([])
            setAddress("");
            setSpinnerLoading(true);
            const results = await getGeocode({ address: value });
            const { lat, lng } = await getLatLng(results[0]);
            ApiService.fetchData("getsellerlocationapi/" + lat + '/' + lng).then((res) => {
                if (res.seller_status == 0) {
                    geocodelocation(lat, lng)
                    setSpinnerLoading(false);
                } else {
                    setLocationError("Chokhi Dhani Foods UAE is currently unavailable at this location. Please choose an alternative location.");
                    setSpinnerLoading(false);
                }
            });

        } catch (error) {
            setLocationError(error);
            setSpinnerLoading(false);
        }
    };

    const geocodelocation = async (lat, lng) => {
        const mapApiUrl = `geocode/json?latlng=${lat},${lng}&key=${constant.GOOGLE_MAP_KEY}`;
        try {
            setSpinnerLoading(true)
            const response = await ApiService.fetchGoogleData(mapApiUrl);
            if (response.status === "OK") {
                const updateAddressObj = {
                    full_address: response.results[0].formatted_address,
                    city: "",
                    state: "",
                    country: "",
                    postal_code: "",
                    user_lat: response.results[0].geometry.location.lat,
                    user_lng: response.results[0].geometry.location.lng,
                };

                response.results[0].address_components.forEach((component) => {
                    component.types.forEach((type) => {
                        switch (type) {
                            case "locality":
                                updateAddressObj.city = component.long_name;
                                break;
                            case "administrative_area_level_1":
                                updateAddressObj.state = component.long_name;
                                break;
                            case "country":
                                updateAddressObj.country = component.long_name;
                                break;
                            case "postal_code":
                                updateAddressObj.postal_code = component.long_name;
                                break;
                            default:
                                break;
                        }
                    });
                });
                setSpinnerLoading(false)
                localStorage.setItem(
                    "DELIVERY_LOCATION",
                    JSON.stringify(updateAddressObj)
                );
                // localStorage.setItem(
                //     "STORE_LOCATION",
                //     JSON.stringify(sellerDetails)
                // );
                window.location.reload();
            }
        } catch (error) {
            setLocationError(error);
        }
    }
    const navigate = useNavigate();
   const handleClick = () =>{

    navigate('/search')

   }
    return (
        <>
            <BrowserView>
                {headerdata?.header_top ? (
                    <div className="top-header">
                        <Container>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-6">
                                    <marquee>{headerdata.header_top}</marquee>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null}
               <header className="header">
                    <div className={state == "" ? "fixed-header header-middle" : " fixed-header header-middle " + state} id="header_top">
                        <div className="container">
                            <div className="header-left">
                                <a href="/" className="logo"><img src={settingData.logo != null ? settingImagePath + settingData.logo : "/img/logodefault.png"} alt={settingData.logo} width={85}
                                    height={57} /></a>
                                  <div className="header-location"  onClick={handleShowLocation}>
                            <h6 className="mb-0 tx-14">Set Delivery Location <i className="ri-arrow-down-s-line"></i></h6>
                            {deliveryLocationData.full_address ? (
                                <div className="location-name">{deliveryLocationData.full_address}</div>
                            ) : (
                                <div className="location-name">Shahjah & Dubai same day Delivery</div>
                            )}
                        </div>
                                {/* <div className="header-location" onClick={handleShowLocation}>
                                    <h6 className="mb-0 tx-14">Set Delivery Location <i className="ri-arrow-down-s-line"></i></h6>
                                    {deliveryLocationData &&
                                        deliveryLocationData.user_lng != "" ? (
                                        <div className="location-name" >
                                            {deliveryLocationData.full_address}
                                        </div>
                                    ) : null}
                                </div> */}
                            </div>
                            <div className="header-center">
                                <div className="header-search" onBlur={handleBlur}>
                                    <form action="#" className="input-wrapper">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="search"
                                            autoComplete="off"
                                            placeholder="Search for products, brands & more..."
                                            required=""
                                            onChange={handleInputChange}
                                            value={searchTerm}
                                        />
                                        {searchResults &&
                                            searchResults.length > 0 &&
                                            searchTerm.trim() !== "" ? (
                                            <div className="header-search-list" id="search_input">
                                                <ul>
                                                    {searchResults.map((value, index) => (
                                                        <a href={value.redirect}>
                                                            <li key={index}>{value.name}</li>
                                                        </a>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <button className="btn btn-search" type="submit" title="submit-button"><i className="d-icon-search"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className="header-right justify-content-end">
                                <ul className="header-right-list">

                                {setSession ? (
                                    <>
                                    <li><a href="/account/wishlist"><i className="ri-heart-3-line">
                                        </i><span>Favorites</span></a></li>

                                    <li><a href="/account/account-overview"><i className="ri-user-line">
                                        </i><span>Account</span></a></li>

                                 </>
                                ) : (
                                    <>
                                        <li>
                                            <a   
                                            href="javascript:void(0)"
                                         onClick={handleShow}
                                        ><i className="ri-heart-3-line">
                                        </i><span>Favorites</span>
                                        </a>
                                        </li>
                                    <li><a
                                    href="javascript:void(0)"
                                    onClick={handleShow}
                                     >
                                        <i className="ri-user-line">
                                        </i><span>Account</span></a></li>
                                        </>
                                   )}

                               <li className="cart-toggle">
                               <a href="javascript:void(0)"
                                        onClick={handleShowCart}
                                        >
                                            <i className="ri-shopping-bag-3-line"></i>
                                            <span>Cart</span>
                                            {showCart ? (
                                            <div className="cart-count">{cartCount}</div>
                                            ) : (
                                                <div className="cart-count">{parsedCartSession.length}</div>
                                                )}
                                        </a>
                                    </li>            
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="container">
                            <HeaderMenu />
                        </div>
                    </div>
                </header>

            </BrowserView>
      
            <MobileView>
                {headerdata?.header_top ? (
                    <div className="top-header">
                        <Container>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-12 ">
                                    <marquee>{headerdata.header_top}</marquee>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null}

                <div className="mobileheader">
                    <div className="mobileheader-middle">
                        <div className="mobileheader-middle-left">
                        <div className="header-location"  onClick={handleShowLocation}>
                            <h6 className="mb-0 tx-12">Set Delivery Location <i className="ri-arrow-down-s-line"></i></h6>
                            {deliveryLocationData.full_address ? (
                                <div className="location-name">{deliveryLocationData.full_address}</div>
                            ) : (
                                <div className="location-name">Shahjah & Dubai same day Delivery</div>
                            )}
                        </div>
                            {/* <div className="header-location" onClick={handleShowLocation}>
                                <h6 className="mb-0 tx-12">Set Delivery Location <i className="ri-arrow-down-s-line"></i></h6>
                                {deliveryLocationData &&
                                    deliveryLocationData.user_lng != "" ? (
                                    <div className="location-name">
                                        {deliveryLocationData.full_address}
                                    </div>
                                ) : null}
                            </div> */}
                        </div>
                        <div className="mobileheader-middle-right">
                            <a 
                            href="javascript:void(0)"
                            className="mcarttoggle"
                            onClick={handleShowCart}
                            >
                                <i className="ri-shopping-bag-3-line"></i>
                                <div className="cart-count">{cartCount}</div>
                            </a>
                        </div>
                    </div>
                    <div className={state == "" ? "ed-header mobileheader-search" : " ed-header mobileheader-search" + state} id="mobile_top">
                    <div className="mobileheader-search-inner" onClick={handleClick}>
                        <i className="d-icon-search me-3"></i>
                        Search for products, brands & more...
                    </div>
                    </div>
                </div>

{/*   <header className="mheader d-flex">
                    <div className="mheader-left">
                        <a
                            href="javascript:void(0)"
                            className="sidenav-trigger"
                            onClick={handleShowMenuCart}
                        >
                            <i className="d-icon-bars2"></i>
                        </a>
                        <a href="/" className="mlogo">
                            <img src="/img/logo.png" alt="logo" width="60" height="40" />
                        </a>
                    </div>
                    <div className="mheader-right">
                        <a className="search-link" href="/search" title="search">
                            <i className="d-icon-search"></i>
                        </a>
                        {setSession ? (
                            <>
                                <a
                                    className="login-link"
                                    href="/account/account-overview"
                                    title="login"
                                >
                                    <i className="d-icon-user"></i>
                                </a>
                            </>
                        ) : (
                            <>
                                <a
                                    className="login-link"
                                    href="javascript:void(0)"
                                    title="login"
                                    onClick={handleShow}
                                >
                                    <i className="d-icon-user"></i>
                                </a>
                            </>
                        )}
                        <a
                            className="cart-toggle"
                            href="javascript:void(0)"
                            title="cart"
                            onClick={handleShowCart}
                        >
                            <i className="d-icon-bag"></i>
                            <span className="cart-count">{cartCount}</span>
                        </a>
                    </div>
                </header> */}
            </MobileView>
            <Modal
                show={showLocation}
                onHide={handleCloseLocation}
                backdrop={backdrop}
                keyboard={false}
                className="locationbox">
                <div className="locationboxbody">
                    {backdrop === "static" ? (
                        null
                    ) : (
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleCloseLocation}
                        ></button>
                    )}
                    <h6 className="mb-2">Delivery Location</h6>
                    <p>Please provide your delivery location to see products at nearby store</p>
                    <button className="detactlocation" onClick={fetchData}>
                        {spinnerLoadingDetect ? <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
                        /> : 'Detect my location'}
                    </button>
                    {locationError && <Alert variant="danger" className="mb-0" style={{ marginTop: '10px' }}>{locationError}</Alert>}
                    <div className="or">OR</div>
                    <input type="text"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                            setValue(e.target.value);
                            setLocationError("");
                        }}
                        placeholder="Search Delivery Location"></input>
                    {spinnerLoading && <div className="text-center"><ColorRing
                        visible={true}
                        height="50"
                        width="50"
                        colors={['#e42128', '#e42128', '#e42128', '#e42128', '#e42128']}
                    /></div>}
                    {data.length > 0 && (
                        <div className="loclist">
                            <ul>
                                {status === "OK" && data.map(({ id, description }) => (
                                    <li key={id} onClick={() => handleSelect(description)}>
                                        {description}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </Modal>
            {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
            <Modal show={showCart} onHide={handleClose} className="right cart-modal">
                {showCart && (
                    <CartModal
                        showcartmodal={showCart}
                        onChildCartData={handleChildCartData}
                    />
                )}
            </Modal>

            <Modal
                show={showMenuCart}
                onHide={handleMenuClose}
                className="left menu-modal"
            >
                {showMenuCart && (
                    <MenuModal
                        showMenucartmodal={showMenuCart}
                        onChildCartData={handleChildMenuCartData}
                    />
                )}
            </Modal>
        </>
    );
}
export default Header;
