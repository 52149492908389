import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import QuickViewModal from "../Modals/quick_view_modal";
import LoginModal from "../Modals/login_modal";
import VariationModal from "../Modals/variation_modal";
import { Swiper, SwiperSlide } from "swiper/react";
import StarRating from "./starrating";
import multiCurrency from "../../Components/Elements/multi_currrency";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserView, MobileView } from "react-device-detect";
import { showToast } from "./toastUtils";
function TagWiseProducts({ indexLoop, onParentData, dataObj }) {
  const didMountRef = useRef(true);
  const [productData, setProductData] = useState(null);
  const [showQuick, setShowQuick] = useState(false);

  const handleShowQuickModal = (data) => {
    setProductData(data);
    setShowQuick(true);
  };
  const handleChildQuickModalData = (status) => {
    setShowQuick(status);
  };
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState();
  const handleChildData = (status) => {
    setShow(status);
  };
  const [tagCategoriesData, setTagCategoriesData] = useState([]);
  const [setSession, SetSession] = useState("");
  useEffect(() => {
    if (didMountRef.current) {
      SetSession(localStorage.getItem("USER_TOKEN"));
      getTagCategoryData();
    }
    didMountRef.current = false;
  }, []);
  const getTagCategoryData = () => {
    setLoading(true);
    const dataString = {
      data: dataObj,
    };
    ApiService.postData("tags-wise-products-new", dataString).then((res) => {
      if (res.status == "success") {
        setTagCategoriesData(res.tagsData);
        setLoading(false);
      }else{
        setLoading(false);
      }
    });
  };
  const [showVariation, setShowVariation] = useState(false);
  const handleShowVariation = (data) => {
    setProductData(data);
    setShowVariation(true);
  };
  const handleChildVariationModalData = (status) => {
    setShowVariation(status);
    onParentData(status);
  }; 

  const addtofav = (productId) => {
    const dataString = {
      product_id: productId,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        if (res.data.notification === "ri-heart-3-line") {
          showToast('success', 'Removed from Wishlist', 1000);
        } else {
          showToast('success', 'Added to Wishlist', 1000);
        }
        var element = document.getElementsByClassName("wishlisticon" + productId)[0];
        if (element) {
            element.classList.remove("ri-heart-3-fill", "ri-heart-3-line");
            element.classList.add(res.data.notification);
        }
      }
    });
  }; 
  return (
    <>
      {loading == true ? (
        <>
          <BrowserView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={250} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </BrowserView>
          <MobileView>
            <div className="row g-2">
              {[...Array(4)].map((_, index) => (
                <div key={index} className="col-lg-3 col-6">
                  <div className="product">
                    <figure className="product-media">
                      <Skeleton variant="text" width={300} height={160} />
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <Skeleton variant="text" width={150} />
                      </h3>
                      <div className="product-price">
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> 
          </MobileView>
        </>
      ) : (
        <> 
          {tagCategoriesData.map((value, index) => {
            if (value.tagProducts.length > 0) {
              if (dataObj.home_display_type === 2) {
                return (
                  <>
                    <div className="page-title mb-4" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/tag/" + value.tag_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div>
                    <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        let mrpValue = parseFloat(subvalue.product_price);
                        let sellingPriceValue = parseFloat(
                          subvalue.product_selling_price
                        );
                        let discount = 0;
                        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                          discount = (
                            ((mrpValue - sellingPriceValue) / mrpValue) *
                            100
                          ).toFixed(2);
                        }
                        return (
                          <>
                            <div className="cproduct" key={indexProduct}>
                              <a href={"/product/" + subvalue.product_slug} className="cproduct-media">
                                <figure className="cproduct-media-img">
                                  <img src={subvalue.product_image != null ? subvalue.product_image : constant.DEFAULT_IMAGE} alt={subvalue.product_name} title={subvalue.product_name} width='280' height='315'></img>
                                </figure>
                                {subvalue.product_label_name && subvalue.product_label_name !== "" ? (
                                  <div className="cproduct-label-group">
                                    {subvalue.product_label_name.split(", ").map((tagvalue, indextag) => {
                                        return (
                                          <label className="cproduct-label label-sale" key={indextag}>{tagvalue}</label>
                                        );
                                      })}
                                  </div>
                                ) : null}
                                <div className="cproduct-action-vertical">
                                  {setSession ? (
                                    subvalue.ufp_id && subvalue.ufp_id > 0 ? (
                                      <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(subvalue.product_id)}><i className={"ri-heart-3-fill wishlisticon" + subvalue.product_id}></i></a>
                                    ) : (
                                      <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(subvalue.product_id)}><i className={"ri-heart-3-line wishlisticon" + subvalue.product_id}></i></a>
                                    )
                                  ) : (
                                    <a href="javascript:void(0)" className="cpav-button" onClick={handleShow}><i className={"ri-heart-3-line wishlisticon" + subvalue.product_id}></i></a>
                                  )}
                                </div>
                                <a href="javascript:;" className="quickviewbutton" onClick={() => {handleShowQuickModal(subvalue);}}>Quick View</a>
                              </a>
                              <div className="cproduct-details">
                                <h2 className="cproduct-name"><a href={"/product/" + subvalue.product_slug}>{subvalue.product_name}</a></h2>
                                <div className="cproduct-price">
                                  <ins className="new-price">{multiCurrency(subvalue.product_selling_price)}</ins>
                                  {discount > 0 ? (
                                    <>
                                      <del className="old-price">{multiCurrency(subvalue.product_price)}</del>
                                      <span className="off-price">{discount}% Off</span>
                                    </>
                                  ) : null}
                                </div>
                                {subvalue.product_rating &&
                                  subvalue.product_rating > 0 ? (
                                  <div className="cratings-container"><StarRating numberOfStars={subvalue.product_rating}/></div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="page-title mb-4" key={index}>
                      <h2 className="mb-0">{dataObj.home_product_name}</h2>
                      <a href={"/collection/tag/" + value.tag_slug} className="viewmorebutton">View More <i className="ri-arrow-right-s-line ms-2"></i></a>
                    </div> 
                    <Swiper
                      loop={true}
                      spaceBetween={15}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: dataObj.home_column,
                        },
                        600: {
                          slidesPerView: dataObj.home_column,
                        },
                        1000: {
                          slidesPerView: dataObj.home_column,
                        },
                      }}>
                      {value.tagProducts.map((subvalue, indexProduct) => {
                        let mrpValue = parseFloat(subvalue.product_price);
                        let sellingPriceValue = parseFloat(
                          subvalue.product_selling_price
                        );
                        let discount = 0;
                        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                          discount = (
                            ((mrpValue - sellingPriceValue) / mrpValue) *
                            100
                          ).toFixed(2);
                        }
                        return (
                          <SwiperSlide key={indexProduct}>
                            <div className="cproduct" key={indexProduct}>
                              <a href={"/product/" + subvalue.product_slug} className="cproduct-media">
                                <figure className="cproduct-media-img">
                                  <img src={subvalue.product_image != null ? subvalue.product_image : constant.DEFAULT_IMAGE} alt={subvalue.product_name} title={subvalue.product_name} width='280' height='315'></img>
                                </figure>
                                {subvalue.product_label_name && subvalue.product_label_name !== "" ? (
                                  <div className="cproduct-label-group">
                                    {subvalue.product_label_name.split(", ").map((tagvalue, indextag) => {
                                        return (
                                          <label className="cproduct-label label-sale" key={indextag}>{tagvalue}</label>
                                        );
                                      })}
                                  </div>
                                ) : null}
                                <div className="cproduct-action-vertical">
                                  {setSession ? (
                                    subvalue.ufp_id && subvalue.ufp_id > 0 ? (
                                      <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(subvalue.product_id)}><i className={"ri-heart-3-fill wishlisticon" + subvalue.product_id} ></i></a>
                                    ) : (
                                      <a href="javascript:void(0)" className="cpav-button" onClick={(e) => addtofav(subvalue.product_id)}><i className={"ri-heart-3-line wishlisticon" + subvalue.product_id} ></i></a>
                                    )
                                  ) : (
                                    <a href="javascript:void(0)" className="cpav-button" onClick={handleShow}><i className={"ri-heart-3-line wishlisticon" + subvalue.product_id}></i></a>
                                  )}
                                </div>
                                <a href="javascript:;" className="quickviewbutton" onClick={() => {handleShowQuickModal(subvalue);}}>Quick View</a>
                              </a>
                              <div className="cproduct-details">
                                <h2 className="cproduct-name"><a href={"/product/" + subvalue.product_slug}>{subvalue.product_name}</a></h2>
                                <div className="cproduct-price">
                                  <ins className="new-price">{multiCurrency(subvalue.product_selling_price)}</ins>
                                  {discount > 0 ? (
                                    <>
                                      <del className="old-price">{multiCurrency(subvalue.product_price)}</del>
                                      <span className="off-price">{discount}% Off</span>
                                    </>
                                  ) : null}
                                </div>
                                {subvalue.product_rating &&
                                  subvalue.product_rating > 0 ? (
                                  <div className="cratings-container"><StarRating numberOfStars={subvalue.product_rating}/></div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </>
                );
              }
            }
          })}
        </>
      )}
      {show && <LoginModal showmodal={show} onChildData={handleChildData} />}
      {showQuick && (
        <QuickViewModal
          showmodal={showQuick}
          productdata={productData}
          onChildData={handleChildQuickModalData}
        />
      )}
      {showVariation && (
        <VariationModal
          showvariationmodal={showVariation}
          productdata={productData}
          onChildData={handleChildVariationModalData}
        />
      )}
    </>
  );
}
export default TagWiseProducts;
